@keyframes spin {
	from {
		transform: rotate(0);
	}
	to {
		transform: rotate(360deg);
	}
}

@keyframes shake {
	10%,
	90% {
		transform: translate3d(-1px, 1px, 0);
	}

	20%,
	80% {
		transform: translate3d(2px, -1px, 0);
	}

	30%,
	50%,
	70% {
		transform: translate3d(-4px, -3px, 0);
	}

	40%,
	60% {
		transform: translate3d(4px, -2px, 0);
	}
}
