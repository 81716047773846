.notice {
	max-width: 500px;
}

.perms {
	list-style: none;
	padding: 0;
	margin: 1em 0;
	font-size: 1.25em;
}
