@value spin from '../animations.module.css';

.contents {
	padding: 20px;
}

.title {
	font-weight: bold;
	font-size: 18px;
	width: 100%;
	padding: 20px;
	border-bottom: 1px solid rgba(255, 255, 255, 0.2);
	text-align: center;
}

.inputField {
	margin-top: 20px !important;
}

.inputField:first-child {
	margin-top: 0 !important;
}

.infoSource {
	font-size: 14px;
	margin-top: 20px;
	text-align: center;
}

.spinner {
	text-align: center;
	width: 100%;
	margin-top: 20px;
}

.spinner__icon {
	display: inline-block;
	vertical-align: top;
	animation: spin linear infinite 4s;
}
