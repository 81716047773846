* {
	box-sizing: border-box;
}

*:focus {
	outline: none;
}

a {
	text-decoration: none;
}

html,
body {
	height: 100%;
}

#root,
.App {
	min-height: 100%;
	height: 1px;
}

html {
	-webkit-tap-highlight-color: transparent;
}

body {
	margin: 0;
	padding: 0;
	background-color: var(--color-background-dark);
	color: var(--color-text-light);
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

body.light {
	background-color: var(--color-background-light);
	color: var(--color-text-dark);
}

body,
input,
button {
	font-family: "Raleway", sans-serif;
}

h1,
h2,
h3,
h4 {
	margin: 0;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}

button {
	border: 0;
}

button::-moz-focus-inner {
	border: 0;
	padding: 0;
}

@keyframes shake {
	10%,
	90% {
		transform: translate3d(-1px, 1px, 0);
	}

	20%,
	80% {
		transform: translate3d(2px, -1px, 0);
	}

	30%,
	50%,
	70% {
		transform: translate3d(-4px, -3px, 0);
	}

	40%,
	60% {
		transform: translate3d(4px, -2px, 0);
	}
}
