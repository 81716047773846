.wrapper {
	display: block;
	position: fixed;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	z-index: 100;
	background-color: rgba(0, 0, 0, 0.5);
	overflow: auto;
	display: flex;
	justify-content: center;
	align-items: center;
	animation: wrapperEntrance 0.2s linear;
	color: #fff;
	padding-top: 30px;
}

.modal {
	background-color: #181818;
	width: 600px;
	max-width: 90%;
	box-shadow: rgba(0, 0, 0, 0.75) 0px 3px 10px;
	margin-top: 2em;
	position: relative;
	animation: entrance 0.2s linear;
	border-radius: 7px;
	height: fit-content;
}
/*
.wrapper.exiting,
.exiting > .modal {
	transition: opacity 0.4s, transform 0.4s;
	opacity: 0;
	transform: scale(0.8);
}
*/
.closeButton {
	position: absolute;
	z-index: 1;
	top: 16px;
	left: 16px;
	color: white;
	font-size: 18px;
	width: 30px;
	height: 30px;
	border-radius: 30px;
	line-height: 1;
	text-align: center;
	display: block;
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;
}

.closeButton:hover {
	background-color: rgba(255, 255, 255, 0.1);
}

.header {
	width: 100%;
	height: 60px;
	border-bottom: 1px solid rgba(255, 255, 255, 0.2);
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
}

.title {
	font-weight: bold;
	font-size: 18px;
}

.contents {
	padding: 20px;
}

.signinButton {
	font-size: 16px;
	margin-top: 20px;
}

@media screen and (max-width: 600px) {
	.modal {
		margin-top: 10px;
	}

	.contents {
		padding: 10px;
	}
}

@keyframes entrance {
	from {
		opacity: 0;
		/*transform: translate3d(0, 30px, 0);*/
		transform: scale(0.8);
	}

	to {
		opacity: 1;
		/*transform: translate3d(0, 0, 0);*/
		transform: scale(1);
	}
}

@keyframes wrapperEntrance {
	from {
		opacity: 0;
	}

	to {
		opacity: 1;
	}
}
