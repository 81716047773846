.button {
	font-size: 20px;
	display: inline-block;
	vertical-align: top;
	border: none;
	border-radius: 50px;
	line-height: 2.4em;
	padding: 0 1em;
	cursor: pointer;
	background-color: white;
	opacity: 0.9;
	transition: opacity 0.2s;
	text-align: center;
}

.button.outlined {
	color: white;
	opacity: 1;
	border: 2px solid rgba(255, 255, 255, 0.7);
	background-color: transparent;
	transition: border-color 0.2s;
}

.button:hover {
	opacity: 1;
}

.button.outlined:hover {
	border-color: white;
}

.button.fullWidth {
	display: block;
	width: 100%;
}

.button.disabled {
	opacity: 0.3;
	pointer-events: none;
}
