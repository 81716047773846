.wrapper {
}

.wrapper.fullScreen {
	width: 100%;
	height: 100%;
}

.fullScreen .video {
	width: 100%;
	height: 100%;
	object-fit: contain;
}
