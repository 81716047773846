@value spin from '../../animations.module.css';

.dialog__contents {
	width: 100%;
	font-size: 16px;
}

.dialog__tile {
	display: none;
}

.dialog__tile.visible {
	display: block;
}

.menu {
	padding: 20px 15px;
}

.menu__title {
	text-align: center;
}

.liveChat {
	padding: 10px;
}

.liveChat__iframe {
	border: 0;
	width: 100%;
	height: 500px;
	position: relative;
}

.hints {
	padding: 20px;
	text-align: center;
	min-width: 250px;
}

.hints__spinner {
	display: inline-block;
	vertical-align: top;
	animation: spin linear infinite 4s;
}

.hints__hint {
	font-size: 14px;
	line-height: 1.3;
}

.hint {
	margin-bottom: 1em;
}

.hint__title {
	font-weight: bold;
	margin-bottom: 0.3em;
}

.button {
	display: block;
	width: 100%;
	background-color: black;
	color: white;
	border-radius: 5px;
	line-height: 2em;
	font-size: 14px;
	text-align: center;
	cursor: pointer;
	user-select: none;
}

.button.inline {
	display: inline-block;
	padding: 0 1em;
	width: auto;
}

.button.spaceTop {
	margin-top: 10px;
}

.button.loading {
	opacity: 0.7;
	pointer-events: none;
}

.button__spinner {
	display: inline-block;
	vertical-align: top;
	animation: spin linear infinite 4s;
}

.form {
	padding: 15px;
}

.form.submitting {
	pointer-events: none;
}

.form__title {
	font-weight: bold;
}

.form__subtitle {
	font-size: 14px;
	margin-top: 5px;
}

.form__success {
	text-align: center;
	font-weight: bold;
	margin: 1em 0;
}

.form__form {
	padding-top: 5px;
}

.backLink {
	text-align: center;
	margin-top: 10px;
	font-size: 14px;
	cursor: pointer;
}

@media screen and (max-width: 400px) {
	.liveChat__iframe {
		height: 400px;
	}
}
