.field {
	width: 300px;
	max-width: 100%;
	font-size: 20px;
	position: relative;
	margin-bottom: 12px;
	display: inline-block;
	vertical-align: top;
}

.field__label {
	font-size: 14px;
	display: block;
	text-align: left;
	margin-bottom: 5px;
}

.field__input {
	width: 100%;
	background-color: rgba(255, 255, 255, 0.8);
	border-radius: 10px;
	border: none;
	padding: 12px 20px;
	font-size: 1em;
	line-height: 1;
}
