@value spin from '../animations.module.css';
@value shake from '../animations.module.css';

.page {
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 20px;
	text-align: center;
}

.image {
	width: 150px;
	height: auto;
	transform-origin: 50% 100%;
	animation: image-animation ease-in-out 5s infinite;
}

.title {
	font-size: 40px;
	font-weight: bold;
	margin-top: 0.5em;
}

.text {
	font-size: 20px;
	max-width: 600px;
	margin-top: 0.5em;
	line-height: 1.3;
	color: #fff;
}

.redirectMessage {
	color: #fff;
	margin-top: 1em;
}

.ctaButton {
	font-size: 20px;
	font-weight: bold;
	background-color: #fff;
	color: black;
	border-radius: 2em;
	display: inline-block;
	vertical-align: top;
	line-height: 2.4em;
	padding: 0 1em;
	cursor: pointer;
	background-color: white;
	opacity: 0.9;
	transition: opacity 0.2s;
	margin-top: 1em;
}

.ctaButton:hover {
	opacity: 1;
}

.ctaButton__spinner {
	display: inline-block;
	height: 1em;
	width: 1em;
	line-height: 2.4em;
	vertical-align: middle;
	animation: spin linear infinite 3s;
}

.confetti {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	pointer-events: none;
}

.clock {
	--clock-color-forground: #45100b;
}

.clock.animate {
	animation: shake 1s cubic-bezier(0.36, 0.07, 0.19, 0.97) infinite;
}

.clock :global(.react-clock__face) {
	background-color: #fdeb86;
	border: 3px solid var(--clock-color-forground);
}

.clock :global(.react-clock__face):before {
	content: "";
	position: absolute;
	top: 5%;
	left: 5%;
	width: 90%;
	height: 90%;
	background-color: #fff8ed;
	border-radius: 100%;
	border: 3px solid var(--clock-color-forground);
}

.clock :global(.react-clock__face):after {
	content: "";
	position: absolute;
	top: 45%;
	left: 45%;
	width: 10%;
	height: 10%;
	background-color: var(--clock-color-forground);
	border-radius: 50px;
}

.clock :global(.react-clock__hand) {
	transition: transform 1s linear;
}

.clock :global(.react-clock__hand__body) {
	background-color: var(--clock-color-forground);
	border-radius: 4px;
}

@keyframes image-animation {
	0% {
		transform: rotate(0);
	}
	25% {
		transform: rotate(5deg);
	}
	75% {
		transform: rotate(-5deg);
	}
	100% {
		transform: rotate(0);
	}
}
