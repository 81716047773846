.videoCall {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	overflow: hidden;

	background-color: black;
	font-family: "Raleway", sans-serif;
}

.incoming {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	text-align: center;
	z-index: 10;

	background: linear-gradient(
		30deg,
		rgba(43, 19, 60, 1) 0%,
		rgba(136, 18, 18, 1) 50%,
		rgba(113, 81, 35, 1) 100%
	);
}

.incoming.ended {
	background: linear-gradient(
		30deg,
		rgba(43, 19, 60, 0.4) 0%,
		rgba(136, 18, 18, 0.4) 50%,
		rgba(113, 81, 35, 0.4) 100%
	);
}

.incoming__title {
	font-size: 30px;
	color: white;
	margin-top: 50px;
}

.incoming__subtitle {
	color: white;
	font-size: 16px;
	margin-top: 10px;
}

.ended .incoming__title,
.ended .incoming__subtitle {
	opacity: 0.7;
}

.incoming__buttons {
	position: absolute;
	bottom: 50px;
	width: 100%;
	height: 50px;
}

.phoneButton {
	display: inline-block;
	width: 60px;
	height: 60px;
	border-radius: 60px;
	line-height: 60px;
	text-align: center;
	cursor: pointer;
}

.phoneButton.answer {
	background-color: #00cc4c;
}

.phoneButton.decline {
	background-color: #ef3248;
}

.phoneButton.end {
	position: absolute;
	z-index: 2;
	bottom: 50px;
	left: calc(50% - 30px);
	background-color: #ef3248;
}

.phoneButton + .phoneButton {
	margin-left: 80px;
}

.phoneButton__icon {
	display: inline-block;
	font-size: 40px;
	color: white;
}

.answer > .phoneButton__icon {
	transform: translate(1px, 2px);
}

.decline > .phoneButton__icon,
.end > .phoneButton__icon {
	transform: rotate(135deg) translate(1px, 0px);
}

.videoWrapper {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	pointer-events: none;
}

.video__player {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

.video__loading {
	color: white;
	font-size: 16px;
	text-align: center;
	position: absolute;
	top: 70px;
	width: 100%;
}

@media screen and (min-width: 1000px) {
	.incoming__title {
		font-size: 50px;
		margin-top: 100px;
	}

	.incoming__subtitle {
		font-size: 24px;
	}

	.incoming__buttons {
		bottom: 100px;
	}

	.phoneButton {
		width: 80px;
		height: 80px;
		border-radius: 80px;
		line-height: 80px;
	}

	.phoneButton + .phoneButton {
		margin-left: 120px;
	}
}

@media screen and (max-width: 600px) {
	.phoneButton.end {
		bottom: 20px;
	}
}
