.page {
	padding-bottom: 80px;
	position: relative;
	min-height: 100%;
}

.stories {
	position: relative;
	z-index: 4;
	margin-top: -12vw;
	padding-bottom: 2em;
}

.section:not(:first-child) {
	margin-top: 2vw;
}

.section__title {
	margin: 0;
	vertical-align: bottom;
	line-height: 1.25vw;
	font-size: 1.4vw;
	padding-left: 4%;
}

.section__stories {
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	overflow-x: auto;

	padding: 0;
	margin: 0.5vw 0 0 0;

	/* Hide scrollbar for IE, Edge and Firefox */
	-ms-overflow-style: none; /* IE and Edge */
	scrollbar-width: none; /* Firefox */
}

/* Hide scrollbar for Chrome, Safari and Opera */
.section__stories::-webkit-scrollbar {
	display: none;
}

.tile {
	width: 21%;
	position: relative;
	overflow: hidden;
	display: inline-block;
	vertical-align: top;
	margin-right: 5px;
	flex-shrink: 0;
	flex-grow: 0;
}

.tile:first-child {
	margin-left: 4%;
}

.tile__box {
	padding: 28.125% 0;
}

.tile__contents {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	overflow: hidden;
	border-radius: 5px;
	-webkit-user-drag: none;
}

.tile__poster {
	width: 100%;
	height: 100%;
	display: block;
	-webkit-user-drag: none;
}

.tile__ribbon {
	position: absolute;
	display: block;
	top: 0;
	left: 0;
	width: 90px;
	height: 90px;
	overflow: hidden;
	transform-origin: top left;
	pointer-events: none;
}

.tile__ribbon__label {
	position: relative;
	display: block;
	left: -25px;
	top: 14px;
	width: 102px;
	font-size: 12px;
	text-transform: uppercase;
	font-weight: bold;
	height: 23px;
	line-height: 25px;
	text-align: center;
	color: #fff;
	box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.3);
	transform: rotate(-45deg) translate3d(0, 0, 0);
}

.tile__ribbon__label.purple {
	background-color: #ab38b4;
}

@media screen and (min-width: 1600px) {
	.tile__ribbon {
		transform: scale(1.5);
	}
}

@media screen and (max-width: 1200px) {
	.stories {
		margin-top: -12vw;
	}

	.tile {
		width: 26%;
	}
}

@media screen and (max-width: 900px) {
	.stories {
		margin-top: -6vw;
	}

	.tile {
		width: 35%;
	}
}

@media screen and (max-width: 600px) {
	.tile__ribbon {
		transform: scale(0.8);
	}

	.stories {
		margin-top: 5vw;
	}

	.tile {
		width: 40%;
	}

	.section__title {
		font-size: 16px;
		line-height: 1;
	}
}
