@import url("https://fonts.googleapis.com/css2?family=Exo+2:ital@0;1&display=swap");

body:global(.darknet) {
	background: #000;
}

.story,
.story input,
.story button {
	font-family: "Exo 2", sans-serif;
}

.intro {
	max-width: 800px;
	font-size: 20px;
	line-height: 1.3;
}

.alertFooter {
	text-align: center;
}
