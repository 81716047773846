.PhoneNumberField .iti {
	width: 100%;
	font-size: 16px;
}

.iti-mobile .iti--container {
	top: 20px !important;
	bottom: 20px !important;
	left: 20px !important;
	right: 20px !important;
}

.PhoneNumberField .iti__flag-container {
}

.PhoneNumberField .iti__selected-flag {
	padding-left: 10px;
}

/* On mobile, this container is added to the body and not inside PhoneNumberField */
.iti__country-list {
	color: #333;
	border-radius: 10px;
	margin-top: 2px;
	max-height: 150px;
	min-width: 280px;
}

.PhoneNumberField .iti__country {
	color: #333;
}

.PhoneNumberField .TextField-input {
	font-size: 20px;
	/*padding-left: 61px !important;*/
}

.iti__flag {
	background-image: url("intl-tel-input/build/img/flags.png");
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
	.iti__flag {
		background-image: url("intl-tel-input/build/img/flags@2x.png");
	}
}
