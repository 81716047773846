.footer {
	position: absolute;
	width: 100%;
	height: 40px;
	bottom: 0;
	left: 0;
	font-size: 14px;
	padding: 0 4%;
}

.footer__link {
	color: var(--color-text-gray);
	margin-left: 2em;
}

.footer__link:first-child {
	margin-left: 0;
}
