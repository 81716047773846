.wrapper {
	display: block;
	position: fixed;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	z-index: 100;
	background-color: rgba(0, 0, 0, 0.5);
	overflow: auto;
	display: flex;
	justify-content: center;
	animation: storyModalWrapperEntrace 0.3s linear;
	color: #fff;
}

.modal {
	background-color: #181818;
	width: 90%;
	max-width: 1000px;
	box-shadow: rgba(0, 0, 0, 0.75) 0px 3px 10px;
	margin-top: 2em;
	position: relative;
	animation: storyModalEntrace 0.3s linear;
	border-top-left-radius: 7px;
	border-top-right-radius: 7px;
}

.wrapper.exiting,
.exiting > .modal {
	transition: opacity 0.4s, transform 0.4s;
	opacity: 0;
	transform: scale(0.8);
}

.closeButton {
	position: absolute;
	z-index: 1;
	top: 1em;
	right: 1em;
	background-color: black;
	color: white;
	font-size: 20px;
	line-height: 1;
	padding: 5px;
	border-radius: 20px;
	width: 30px;
	height: 30px;
	text-align: center;
	display: block;
	z-index: 10;
}

.header {
	width: 100%;
	height: 0;
	padding: 28.125% 0;
	position: relative;
	border-top-left-radius: 7px;
	border-top-right-radius: 7px;
	overflow: hidden;
}

.header::after {
	content: "";
	width: 100%;
	height: 80px;
	position: absolute;
	bottom: 0;
	left: 0;
	background: linear-gradient(0deg, #181818 0%, rgba(0, 0, 0, 0) 100%);
	z-index: 10;
}

.header__contents {
	position: absolute;
	bottom: 2em;
	left: 3em;
	z-index: 20;
}

.modal__inner {
	padding: 0 3em;
}

.description {
	font-size: 18px;
}

.video {
	object-fit: cover;
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	opacity: 0;
	transition: opacity 0.3s;
	z-index: 2;
}

.video.playing {
	opacity: 1;
}

.poster {
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 1;
}

.title {
	display: flex;
	align-items: flex-end;
	margin: 0;
	opacity: 0;
	transition: opacity 0.3s;
	height: 70px;
	width: 100%;
}

.title.visible {
	opacity: 1;
}

.title__image {
	max-width: 100%;
	max-height: 100%;
	display: block;
}

@media screen and (max-width: 600px) {
	.modal {
		margin-top: 10px;
	}

	.modal__inner {
		padding: 0 1em;
	}

	.closeButton {
		top: 10px;
		right: 10px;
	}

	.header__contents {
		bottom: 0;
		left: 1em;
		right: 1em;
	}

	.ctaButton {
		font-size: 14px;
	}
}

@keyframes storyModalEntrace {
	from {
		opacity: 0;
		/*transform: translate3d(0, 30px, 0);*/
		transform: scale(0.8);
	}

	to {
		opacity: 1;
		/*transform: translate3d(0, 0, 0);*/
		transform: scale(1);
	}
}

@keyframes storyModalWrapperEntrace {
	from {
		opacity: 0;
	}

	to {
		opacity: 1;
	}
}
