@import url("https://fonts.googleapis.com/css2?family=Chelsea+Market&display=swap");

body:global(.the-anchor) {
	background: #000;
}

.story,
.story input,
.story button {
	font-family: "Chelsea Market", sans-serif;
}

.intro {
	max-width: 800px;
	font-size: 20px;
	line-height: 1.3;
}
