.story {
	width: 100%;
	height: 100%;
}

.transitionVideo {
	position: absolute;
	top: 50%;
	left: 50%;
	width: 100%;
	min-width: 500px;
	height: auto;
	z-index: 1;
	transform: translate(-50%, -50%);
	opacity: 0.01;
}

.transitionVideo.loaded {
	/* this is for making the background truely black. Should be fixed at the video level and set back to 1. */
	opacity: 0.85;
}
