.header {
	position: fixed;
	z-index: 10;
	height: 68px;
	width: 100%;
	top: 0;
	left: 0;
	padding: 0 4%;
	opacity: 1;
	transition: opacity 0.1s;
	background-color: transparent;
	background-image: linear-gradient(
		to bottom,
		rgba(0, 0, 0, 0.7) 10%,
		rgba(0, 0, 0, 0)
	);
	transition: background-color 0.3s, background-image 0.3s;
}

body:global(.light) .header {
	background-color: var(--color-background-dark);
}

.header.scrolled {
	background-color: rgb(20, 20, 20);
}

.title {
	margin: 0;
	font-size: 28px;
	line-height: 68px;
	display: inline-block;
	vertical-align: top;
	text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.45);
	color: #fff;
}

.right {
	position: absolute;
	top: 0;
	right: 4%;
	height: 100%;
	display: flex;
	align-items: center;
}

.accountMenuWrapper {
	position: relative;
}

.accountButton {
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: white;
	border-radius: 20px;
	padding: 7px 10px;
}

.accountButton__icon {
	color: rgb(20, 20, 20);
	display: inline-block;
	vertical-align: top;
	cursor: pointer;
}

.accountButton__icon.menu {
	margin-right: 5px;
}

.accountMenu {
	position: absolute;
	top: 100%;
	right: 0;
	margin-top: 10px;
	width: 250px;
	background-color: white;
	border-radius: 5px;
	padding: 5px 0;
	box-shadow: rgba(0, 0, 0, 0.75) 0px 1px 5px;
}

.accountMenu__item {
	display: block;
	width: 100%;
	color: #000;
	padding: 10px 20px;
	display: flex;
	align-items: center;
	line-height: 1;
	cursor: pointer;
	user-select: none;
}

.accountMenu__item:hover {
	background-color: rgba(0, 0, 0, 0.05);
}

.accountMenu__item.primary {
	font-weight: 500;
}

@media screen and (max-width: 600px) {
	.header {
		height: 50px;
	}

	.title {
		font-size: 20px;
		line-height: 50px;
	}

	.accountButton {
		transform: scale(0.7);
		transform-origin: center right;
	}

	.accountMenu {
		margin-top: 5px;
	}
}

@media screen and (max-height: 500px) {
	.header {
		/*opacity: 0;*/
	}
}
