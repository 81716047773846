@value spin from '../../animations.module.css';

.button {
	background-color: white;
	color: #000;
	font-size: 16px;
	border-radius: 0.4em;
	display: inline-block;
	padding: 0.7em 2.3em;
	font-weight: bold;
	margin-top: 1em;
	text-align: center;
	vertical-align: top;
	line-height: 1;
}

.button:hover {
	background-color: #eee;
	cursor: pointer;
}

.button.featured {
	font-size: 1.5vw;
	border-radius: 0.6vw;
	padding: 1vw 3vw;
	margin-top: 1.5vw;
}

.spinner {
	display: block;
	height: 16px;
	width: 16px;
	vertical-align: top;
	animation: spin linear infinite 3s;
}

.featured .spinner {
	height: 1.5vw;
	width: 1.5vw;
}

@media screen and (max-width: 600px) {
	.button {
		font-size: 14px;
	}

	.button.featured {
		font-size: 1em;
		border-radius: 0.3em;
		padding: 0.5em 1.5em;
		font-weight: bold;
		margin-top: 0.6em;
	}

	.featured .spinner {
		height: 1em;
		width: 1em;
	}
}
