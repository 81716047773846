.form {
	display: flex;
	flex-direction: column;
	align-items: center;
	font-size: 16px;
}

.form.submitting {
	filter: blur(10px);
	pointer-events: none;
	transition: filter 5s;
}

.form__field {
	margin-top: 20px !important;
}

.form__error {
	margin-top: 15px;
	height: 15px;
	font-size: 15px;
	line-height: 1;
	color: #d21f3c;
}
