@value spin from '../animations.module.css';

.widget {
	width: 100%;
	height: 100%;
	min-height: 150px;
	min-width: 250px;
}

.widget.loading {
	display: flex;
	align-items: center;
	justify-content: center;
}

.spinner {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100%;
}

.spinner__icon {
	animation: spin linear infinite 4s;
}
