.field {
	justify-content: center;
}

.field > div:last-child > .TextField-input.otp {
	margin-right: 0;
}

.field__input {
	composes: field__input from "./TextField.module.css";

	font-size: 20px;
	padding: 12px 0;
	width: 40px !important;
	margin-right: 5px;
}
