body:global(.wizards) {
	background: #000;
}

.intro {
	width: 90%;
	max-width: 500px;
	display: inline-block;
	vertical-align: top;
	border-radius: 50%;
	overflow: hidden;
	position: relative;
}

.intro__video {
	width: 100%;
	height: auto;
	object-fit: contain;
	display: inline-block;
	vertical-align: top;
	opacity: 0.9;
}

.intro__playButton {
	position: absolute;
	font-size: 50px;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	transition: opacity 0.2s;
	cursor: pointer;
}

.intro__playButton.playing {
	opacity: 0;
	pointer-events: none;
}

.intro__skipButton {
	margin-top: 10px;
	font-size: 14px;
	line-height: 1;
	cursor: pointer;
	transition: opacity 0.2s;
	opacity: 0;
	pointer-events: none;
}

.intro__skipButton.visible {
	opacity: 0.8;
	pointer-events: auto;
}

.intro__skipButton.visible:hover {
	opacity: 1;
}
