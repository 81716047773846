.form {
	margin-top: 50px;
}

.tiles {
	display: grid;
}

.tile {
	grid-column: 1;
	grid-row: 1;
	display: flex;
	flex-direction: column;
	align-items: center;
	pointer-events: none;
	opacity: 0;
	transform: translateY(-100%);
	transition: opacity 0.5s, transform 0.5s;
	min-width: 300px;
}

.tile.entered {
	pointer-events: auto;
	opacity: 1;
	transform: translateY(0);
}

.tile.exited {
	opacity: 0;
	pointer-events: none;
	transform: translateY(100%);
}

.question {
	font-size: 20px;
}

.answer {
	margin-top: 10px;
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.stars {
	display: flex;
	flex-direction: row;
}

.star {
	cursor: pointer;
	padding: 0 5px;
}

.star.hovered > .star__icon {
	fill: rgba(255, 255, 255, 0.2);
}

.star.selected > .star__icon {
	fill: #fff !important;
}

.submitButton {
	margin-top: 10px !important;
}

.submitButton__icon {
	margin-left: 0.2em;
	font-size: 1.2em;
	vertical-align: middle;
}

.nextButton {
	margin-top: 10px;
}
