.resend {
	font-size: 15px;
	margin-top: 3em;
}

.resend__action {
	display: inline-block;
	vertical-align: top;
	min-width: 2em;
	text-align: left;
	margin-left: 0.1em;
}

.resend__link {
	font-weight: bold;
	cursor: pointer;
	user-select: none;
}
