.logo {
	width: 300px;
	max-width: 80%;
	height: auto;
}

.tilesContainer {
	position: relative;
	z-index: 2;
	width: 100%;
	height: 100%;
	overflow: hidden;
	transition: filter 0.5s;
}

.tilesContainer.loading {
	filter: blur(10px);
	pointer-events: none;
	transition: filter 5s;
}

.tile {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	z-index: 2;
	text-align: center;
}

.animation1 .tile.animateIn {
	animation: tile-animation-1 2s linear reverse backwards;
	animation-delay: 2s;
}

.animation1 .tile.animateOut {
	animation: tile-animation-1 2s linear forwards;
	pointer-events: none;
}

.animation2 .tile.animateIn {
	animation: tile-animation-2 0.9s linear reverse backwards;
	animation-delay: 0.9s;
}

.animation2 .tile.animateOut {
	animation: tile-animation-2 0.9s linear forwards;
	pointer-events: none;
}

.tile.invisible {
	pointer-events: none;
	opacity: 0;
}

.tile__contents {
	overflow: auto;
	padding: 20px;
}

.tile.fullWidth .tile__contents {
	width: 100%;
}

.bullets {
	max-width: 500px;
	text-align: left;
	margin-bottom: 25px;
}

.bullets__bullet {
	width: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;
	margin-top: 25px;
}

.bullets__bullet__icon {
	flex-shrink: 0;
	flex-grow: 0;
	margin-right: 15px;
	font-size: 25px;
	width: 2em;
	height: 2em;
	border-radius: 50%;
	background-color: white;
	color: #000;
	text-align: center;
	line-height: 2em;
}

.form__error {
	margin-top: 15px;
	height: 15px;
	font-size: 15px;
	line-height: 1;
	color: #d21f3c;
}

.resend {
	font-size: 15px;
	margin-top: 3em;
}

.resend__action {
	display: inline-block;
	vertical-align: top;
	min-width: 2em;
	text-align: left;
	margin-left: 0.1em;
}

.resend__link {
	font-weight: bold;
	cursor: pointer;
	user-select: none;
}

@keyframes tile-animation-1 {
	0% {
		opacity: 1;
	}
	10% {
		transform: translate3d(-1px, 1px, 0);
		opacity: 1;
	}

	20%,
	80% {
		transform: translate3d(2px, -1px, 0);
	}

	30%,
	50%,
	70% {
		transform: translate3d(-4px, -3px, 0);
	}

	40%,
	60% {
		transform: translate3d(4px, -2px, 0);
	}

	90% {
		transform: translate3d(-1px, 1px, 0);
	}

	100% {
		opacity: 0;
		filter: blur(10px);
	}
}

@keyframes tile-animation-2 {
	0% {
		opacity: 1;
	}
	10% {
		transform: translate3d(-1px, 0, 0);
		filter: invert(80%);
		opacity: 1;
	}

	20%,
	80% {
		transform: translate3d(3px, 0, 0);
		filter: invert(0);
	}

	30%,
	50%,
	70% {
		transform: translate3d(-6px, 0, 0);
		filter: invert(80%);
	}

	40%,
	60% {
		transform: translate3d(6px, 0, 0);
		filter: invert(0);
	}

	90% {
		transform: translate3d(-3px, 0, 0);
		filter: invert(80%);
	}

	100% {
		opacity: 0;
		filter: invert(0) blur(10px);
	}
}

@media screen and (max-width: 600px) {
	.bullet {
		align-items: flex-start;
	}
}
