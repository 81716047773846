@value spin from '../animations.module.css';

.page {
	padding-top: 100px;
	padding-bottom: 80px;
	position: relative;
	min-height: 100%;
}

.spinner {
	text-align: center;
	width: 100%;
	margin-top: 20px;
}

.spinner__icon {
	display: inline-block;
	vertical-align: top;
	animation: spin linear infinite 4s;
}

.personalInfoItem {
	padding: 0;
}

.personalInfoItem.disabled {
	opacity: 0.3;
	pointer-events: none;
}

.editLink {
	position: absolute;
	top: 0;
	right: 0;
	font-weight: 600;
	cursor: pointer;
}

.editForm {
	margin-top: 10px;
}

.editForm__error {
	color: #d21f3c;
}

.submitButtonSpinner {
	animation: spin linear infinite 4s;
}

@media screen and (max-width: 600px) {
	.page {
		padding-top: 70px;
	}
}
