.alert {
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	text-align: left;
}

.body {
	width: 90%;
	max-width: 500px;
	background-color: rgba(255, 255, 255, 0.1);
	background: linear-gradient(
		90deg,
		rgba(255, 255, 255, 0.1) 0%,
		rgba(255, 255, 255, 0.05) 80%
	);
	border-radius: 10px;
	box-shadow: 0 0 12px 3px rgba(0, 0, 0, 0.5);
	padding: 2em;

	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	align-items: center;
	gap: 2em;
}

.icon {
	flex-shrink: 0;
	flex-grow: 0;
}

.body__contents {
	width: 100%;
}

.title {
	font-size: 1.5em;
	text-transform: uppercase;
	margin-bottom: 10px;
}

.text {
	font-size: 16px;
	line-height: 1.3;
}

.text > p:first-child {
	margin-top: 0;
}

.text > p:last-child {
	margin-bottom: 0;
}

.footer {
	margin-top: 10px;
}

@media screen and (max-width: 600px) {
	.body {
		width: 100%;
		padding: 1em;
		flex-direction: column;
		text-align: center;
		gap: 1em;
	}
}
