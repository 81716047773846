.featured {
	width: 100%;
	position: relative;
	height: 56.25vw;
}

.bg {
	position: absolute;
	z-index: 1;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	overflow: hidden;
}

.bg::after {
	content: "";
	width: 100%;
	height: 100px;
	position: absolute;
	bottom: 0;
	left: 0;
	background: linear-gradient(0deg, #141414 0%, rgba(0, 0, 0, 0) 100%);
}

.info {
	position: absolute;
	left: 4%;
	top: 14vw;
	z-index: 2;
}

.title {
	font-size: 6vw;
	margin: 0;
}

.title__image {
	width: 30vw;
	height: auto;
	display: block;
}

.description {
	font-size: 18px;
	line-height: 1.3;
	text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.45);
	margin: 1.5vw 0 0 0;
}

.bg__video,
.bg__image {
	object-fit: cover;
	width: 100%;
	height: 100%;
}

@media screen and (max-width: 600px) {
	.featured {
		height: 66.25vw;
	}

	.info {
		top: auto;
		left: 0;
		bottom: 2vw;
		width: 100%;
		text-align: center;
		padding: 0 2em;
	}

	.title__image {
		width: 50vw;
		display: inline-block;
	}

	.description {
		font-size: 16px;
	}
}
